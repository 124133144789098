/**
Markdown
 */
.markdown {
  line-height: $line-height-lg;

  > :first-child {
    margin-top: 0;
  }

  > :last-child,
  > :last-child .highlight {
    margin-bottom: 0;
  }

  > {
    h1, h2, h3, h4, h5, h6 {
      margin-top: 2rem;
      margin-bottom: .5rem;
    }

    h1 {
      font-size: $h2-font-size;
      line-height: $h2-line-height;
    }

    h2 {
      font-size: $h3-font-size;
      line-height: $h3-line-height;
    }

    h3 {
      font-size: $h4-font-size;
      line-height: $h4-line-height;
    }

    h4 {
      font-size: $h5-font-size;
      line-height: $h5-line-height;
    }
  }

  > hr {
    @include media-breakpoint-up(md) {
      margin-top: 3em;
      margin-bottom: 3em;
    }
  }

  > table {
    @extend .table, .table-bordered;
  }

  img {
    border-radius: $border-radius;
  }
}