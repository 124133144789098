.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: $gray-500;
  overflow: hidden;
  user-select: none;
  padding: calc(.25rem - 1px) .25rem;
  height: 1.25rem;
  border: 1px solid transparent;
  min-width: 1.25rem;

  @at-root a#{&} {
    color: $white;
  }

  &:empty {
    display: inline-block;
    width: $badge-empty-size;
    height: $badge-empty-size;
    min-width: 0;
    min-height: auto;
    padding: 0;
    border-radius: $border-radius-full;
  }

  .avatar {
    box-sizing: content-box;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 .5rem 0 -.5rem;
  }
}

// bordered
.badge-outline {
  background-color: transparent;
  border: 1px solid currentColor;
}

.badge-pill {
  border-radius: 100px;
  min-width: 1.75em;
}