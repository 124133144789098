@mixin subheader($include-color: true, $include-line-height: true) {
  font-size: $h6-font-size;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: .04em;

  @if $include-line-height {
    line-height: $h6-line-height;
  }

  @if ($include-color) {
    color: $text-muted;
  }
}

@mixin scrollbar($is-dark: false) {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    transition: .3s background;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;

    @if ($is-dark) {
      background: transparent;
    } @else {
      background: $gray-400;
    }
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: $gray-500;

    @if ($is-dark) {
      background: mix($white, $dark, 20%);
    } @else {
      background: $gray-500;
    }
  }
}


@mixin autodark-image {
  filter: brightness(0) invert(1);
}

@mixin dark-mode {
  @if $enable-dark-mode {
    $selector: &;

    @media not print {
      @if ($selector) {
        .theme-dark & {
          @content
        }
      } @else {
        .theme-dark {
          @content
        }
      }
    }

    @media not print and (prefers-color-scheme: dark) {
      @if ($selector) {
        .theme-dark-auto & {
          @content
        }
      } @else {
        .theme-dark-auto {
          @content
        }
      }
    }
  }
}