// stylelint-disable declaration-no-important

/**
SOCIAL COLORS
 */
@each $color, $value in $social-colors {
  .bg-#{$color} {
    color: #fff !important;
    background: $value !important;
  }

  .text-#{$color} {
    color: $value !important;
  }
}


@if $enable-extra-colors {
  @each $color, $value in map-merge($colors, (dark: $dark, muted: $text-muted, white: $white)) {
    .bg-#{$color} {
      background: $value;
    }

    .text-#{$color} {
      color: $value !important;
    }

    .bg-#{$color}-lt {
      color: theme-color-darker($value) !important;
      background: theme-color-lighter($value, true) !important;
    }
  }
}
